/* eslint-disable no-unsafe-optional-chaining */
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { SEO } from "gatsby-theme-core";
import { normalizedData } from "@lendex/helper/methods";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import {
    PageHeader03,
    ProjectOverview,
    ProjectContent,
    CompleteHisory,
    ProjectSummery,
    ProjectHero,
} from "gatsby-theme-container";

const ProjectDetailsTemplate = ({ data, location }) => {
    const paths = location?.pathname?.split("/");
    const { content: alldata, ...frontmatter } = data?.project;
    const content = normalizedData(alldata || []);

    return (
        <Layout>
            <SEO pageName="Project Details" isBlogPost />
            <HeaderOne
                hasBorder
                data={{ ...data?.header, ...data?.navigation }}
            />
            <PageHeader03
                data={{
                    prevList: [
                        { title: "Home", path: "/" },
                        { title: paths[1], path: `/${paths[1]}` },
                    ],
                }}
            />

            <main>
                <div className="project-details section-space-ptb">
                    <div className="container">
                        <div className="grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-10">
                            <div className="md:col-span-9 md:mr-10">
                                <ProjectHero data={frontmatter} />
                                <ProjectContent
                                    data={content?.["project-content"]}
                                />
                                <CompleteHisory
                                    data={content?.["complete-history-section"]}
                                />
                                <ProjectSummery
                                    data={content?.["project-summery-section"]}
                                />
                            </div>
                            <div className="md:col-span-3">
                                <ProjectOverview data={frontmatter} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <FooterOne data={{ ...data?.footer, ...data?.contact }} />
        </Layout>
    );
};
export const query = graphql`
    query ProjectDetailsTemplateQuery($slug: String!) {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        allProject(filter: { slug: { ne: $slug } }) {
            nodes {
                ...Project01
            }
        }
        project(slug: { eq: $slug }) {
            ...ProjectDetails
        }
    }
`;

ProjectDetailsTemplate.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        allProject: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        project: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ProjectDetailsTemplate;
